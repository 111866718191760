<template>
  <div>
    <router-link v-if="!edit" to="/create-report">
      <button class="btn my-2 d-flex btn-info">
        <i class="mr-2 material-icons-outlined">arrow_back</i> Back to Create
        Report
      </button>
    </router-link>
    <h6 class="my-4">
      If the company you want to create a report for is not listed below, Please
      <strong class="text-primary">Create a New Company</strong>.
    </h6>
    <div
      class="row justify-content-center"
      v-if="disabledUpdate && activeCompany.id !== -1"
    >
      <div class="col-12 col-md-6 col-lg-5 col-xl-5 my-2">
        <v-select
          @search="GET_COMPANIES_LIST"
          label="name"
          class="style-chooser"
          @option:selected="SET_SELECTED_COMPANY"
          :clearable="false"
          :value="
            activeCompany.id === -1 || activeCompany.id === -2
              ? null
              : activeCompany
          "
          placeholder="Search company name"
          :options="companies"
        ></v-select>
      </div>
      <div
        class="
          col-12 col-md-1 col-lg-1
          d-flex
          align-items-center
          justify-content-center
          col-xl-1
          my-2
        "
      >
        <strong>or</strong>
      </div>
      <div class="col-12 col-md-5 col-lg-4 col-xl-4 my-2">
        <button
          @click="
            activeCompany = {
              id: -1,
              status: true,
              name: '',
              email: '',
              taxNo: '',
              address: '',
            };
            disabledUpdate = false;
          "
          class="btn btn-primary btn-lg d-flex btn-block justify-content-center"
        >
          <i class="ml-2 material-icons-outlined">add</i> Create New Company
        </button>
      </div>
    </div>
    <div
      id="company-detail"
      class="row justify-content-center mt-4"
      v-if="activeCompany.status"
    >
      <div class="col-12 col-md-6 col-lg-5">
        <div v-if="!disabledUpdate && activeCompany.id === -1">
          <div class="my-4">
            <button
              @click="
                activeCompany.status = false;
                activeCompany.id = -2;
                disabledUpdate = true;
              "
              class="btn btn-sm d-flex btn-info"
            >
              <i class="mr-2 material-icons-outlined">arrow_back</i> Back
            </button>
          </div>
          <h5 class="my-4 text-primary">
            Create New Company
            <hr />
          </h5>
        </div>
        <div
          :class="`d-flex justify-content-${
            edit ? 'end' : 'end'
          } align-items-center mb-4`"
        >
          <button
            v-if="disabledUpdate"
            @click="disabledUpdate = false"
            class="btn btn-warning d-flex justify-content-end"
          >
            Edit Company
          </button>
        </div>
        <div
          class="alert text-center"
          v-if="alert.status"
          :class="`alert-${this.alert.variant}`"
        >
          {{ alert.message }}
        </div>
        <div class="form-group">
          <label for="name">Company Owner</label>
          <span class="ml-2 badge badge-danger">required</span>
          <input
            class="form-control"
            id="name"
            maxlength="50"
            :disabled="disabledUpdate"
            v-model="activeCompany.ownerName"
            required
            aria-describedby="emailHelp"
            placeholder="Enter Company Owner ex: John Doe"
          />
        </div>
        <div class="form-group">
          <label for="name">Company Name</label>
          <span class="ml-2 badge badge-danger">required</span>
          <input
            class="form-control"
            id="name"
            maxlength="50"
            :disabled="disabledUpdate"
            v-model="activeCompany.name"
            required
            aria-describedby="emailHelp"
            placeholder="Enter Company Name ex: Doe Ltd."
          />
        </div>
        <div class="form-group">
          <label for="address">Company Address</label>
          <span class="ml-2 badge badge-danger">required</span>
          <input
            class="form-control"
            id="address"
            maxlength="50"
            :disabled="disabledUpdate"
            v-model="activeCompany.address"
            required
            aria-describedby="emailHelp"
            placeholder="Enter Address ex: Eat Street"
          />
        </div>
        <div class="form-group">
          <label for="email">Company Email</label>
          <span class="ml-2 badge badge-danger">required</span>
          <input
            class="form-control"
            id="email"
            maxlength="50"
            :disabled="disabledUpdate"
            v-model="activeCompany.email"
            required
            aria-describedby="emailHelp"
            placeholder="Enter Email Address ex: example@example.com"
          />
        </div>
        <div class="form-group">
          <label for="email">Company Phone</label>
          <span class="ml-2 badge badge-danger">required</span>
          <input
            class="form-control"
            id="email"
            maxlength="50"
            :disabled="disabledUpdate"
            v-model="activeCompany.phone"
            required
            aria-describedby="emailHelp"
            placeholder="Enter Phone Number ex: 123456789"
          />
        </div>
        <div class="form-group">
          <label for="email">Company Postcode</label>
          <span class="ml-2 badge badge-danger">required</span>
          <input
            class="form-control"
            id="email"
            maxlength="50"
            :disabled="disabledUpdate"
            v-model="activeCompany.postcode"
            required
            aria-describedby="emailHelp"
            placeholder="Enter Postcode ex: 0123"
          />
        </div>
        <div class="form-group">
          <label for="email">State</label>
          <span class="ml-2 badge badge-danger">required</span>
          <v-select
            :searchable="false"
            class="style-chooser"
            :disabled="disabledUpdate"
            :value="activeCompany.state"
            @option:selected="(e) => (activeCompany.state = e)"
            :clearable="false"
            placeholder="Select State of Company"
            :options="states"
          >
          </v-select>
        </div>
        <div class="form-group">
          <label for="email">Notes</label>
          <span class="ml-2 badge badge-danger">required</span>
          <textarea
            class="form-control"
            id="email"
            rows="5"
            maxlength="50"
            :disabled="disabledUpdate"
            v-model="activeCompany.note"
            required
            aria-describedby="emailHelp"
            placeholder="Enter Note"
          />
        </div>
        <div
          :class="`mt-4 text-right flex-flow-row d-flex justify-content-${
            disabledUpdate || activeCompany.id === -1 ? 'end' : 'between'
          }`"
        >
          <button
            v-if="!disabledUpdate && activeCompany.id !== -1"
            :disabled="activeCompany.id === -1"
            @click="
              activeCompany = { ...undoCompany };
              disabledUpdate = true;
            "
            class="btn my-2 d-flex btn-danger"
          >
            <i class="mr-2 material-icons-outlined">refresh</i> Undo
          </button>
          <router-link
            :to="`/${edit ? 'update' : 'create'}-report/${
              $route.params.reportId
            }/product`"
            v-if="disabledUpdate"
          >
            <button
              @click="
                $store.commit('SET_UPDATE_REPORT', {
                  key: 'company',
                  data: activeCompany,
                })
              "
              class="btn my-2 d-flex btn-primary"
            >
              Continue to Step 2
              <i class="ml-2 material-icons-outlined">arrow_forward</i>
            </button>
          </router-link>
          <button
            v-else-if="activeCompany.id !== -1"
            @click="SET_UPDATE_COMPANY_INFORMATION"
            :disabled="loading"
            class="btn my-2 d-flex btn-warning"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>
            Update Company
          </button>
          <button
            v-else
            @click="SET_NEW_COMPANY_INFORMATION"
            :disabled="loading"
            class="btn my-2 d-flex btn-success"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>
            Create Company
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyActions from "./companyActions";
export default {
  mixins: [CompanyActions],
  data() {
    return {
      loading: false,
      alert: { status: false, message: "", variant: "success" },
    };
  },
  props: {
    edit: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    CONTROL_VALIDATION() {
      if (
        this.activeCompany.name === "" ||
        this.activeCompany.email === "" ||
        this.activeCompany.phone === "" ||
        this.activeCompany.note === "" ||
        this.activeCompany.postCode === "" ||
        this.activeCompany.ownerName === "" ||
        this.activeCompany.address === ""
      )
        return true;
      return false;
    },
    async SET_UPDATE_COMPANY_INFORMATION() {
      if (this.CONTROL_VALIDATION()) return;
      this.loading = true;
      const response = await this.$api.put(
        `Companies/${this.activeCompany.id}`,
        this.activeCompany
      );
      if (response.result === "OK" && response.message === "OK") {
        this.alert = {
          status: true,
          variant: "success",
          message: "Company saved successfully",
        };
        setTimeout(() => {
          this.alert.status = false;
          this.disabledUpdate = true;
          this.undoCompany = { ...this.activeCompany };
        }, 1500);
      } else {
        this.alert = {
          status: true,
          variant: "danger",
          message: "Something went wrong. Please Try Again",
        };
      }
      this.loading = false;
    },
    async SET_NEW_COMPANY_INFORMATION() {
      if (this.CONTROL_VALIDATION()) return;
      this.loading = true;
      const response = await this.$api.post(`Companies`, this.activeCompany);
      if (response.result === "OK" && response.message === "OK") {
        this.alert = {
          status: true,
          variant: "success",
          message: "Company saved successfully",
        };
        setTimeout(() => {
          this.alert.status = false;
          this.disabledUpdate = true;
          this.undoCompany = { ...this.activeCompany, id: response.data };
          this.activeCompany = {
            ...this.activeCompany,
            id: response.data,
          };
        }, 1500);
      } else {
        this.alert = {
          status: true,
          variant: "danger",
          message: "Something went wrong. Please Try Again",
        };
      }
      this.loading = false;
    },
  },
  mounted() {
    this.GET_COMPANIES_LIST(
      "a",
      () => false,
      () => false
    );
    this.activeCompany = this.$store.state.createReport.createReport.company;
  },
};
</script>

<style>
.flex-flow-row {
  flex-flow: row wrap;
}
</style>