<template>
  <div>
    <choose-company edit />
  </div>
</template>

<script>
import chooseCompany from "@/components/createReport/chooseCompany/index.vue";
export default {
  components: {
    chooseCompany,
  },
};
</script>

<style>
</style>