export default {
    data() {
        return {
            setmout: -1,
            companies: [],
            activeCompany: {
                status: false,
                name: "",
                email: "",
                taxNo: "",
                address: "",
                state: "",
                postcode: "",
                note: "",
                phone: "",
                id: -2,
            },
            disabledUpdate: true,
            states: [
                'NSW',
                'QLD',
                'SA',
                'WA',
                'TAS',
                'VIC',
                'ACT',
                'NT'
            ],
            undoCompany: { status: false, id: -2 },
        }
    },
    methods: {
        GET_COMPANIES_LIST(search = "", loading, callback = () => false) {
            loading(true);
            if (this.setmout != -1) clearTimeout(this.setmout);
            this.setmout = setTimeout(async () => {
                const response = await this.$api.get(`Companies?searchKey=${search}&limit=10&offset=1`);
                if (response.result === "OK" && response.message === "OK") {
                    this.companies = response.data.items;
                    callback(response.data[0])
                } else {
                    callback(false)
                    this.companies = [];
                }
                loading(false);
            }, 500);
        },
        SET_SELECTED_COMPANY(row) {
            this.disabledUpdate = true;
            this.activeCompany = { ...row, status: true };
            this.undoCompany = { ...row, status: true };
            setTimeout(() => {
                document.getElementById('company-detail').scrollIntoView();
            }, 250);
        },
    }
}